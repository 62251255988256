@import url('https://fonts.googleapis.com/css2?family=Quicksand&family=Roboto&display=swap');

.App {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Quicksand', sans-serif;
  background-color: #FBE8A6;
  padding: 10px;
}

.backButton{
  width: auto;
  height: 40px;
  margin: auto;
}