.projects-card {
  border-radius: 13px;
  margin: 2rem;
}
  
#projects-footer {
  background-color: #FFFFF0;
}
  
.projects-photo{
  width: 180px;
  height: auto;
  padding: 5px;
}