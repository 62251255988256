.involvement-card {
    border-radius: 20px !important;
    margin: 2rem;
}
  
.involvement-photo {
    width: 180px;
    height: auto;
    padding: 5px;
}
  
#involvement-footer {
    background-color: #FFFFF0;
}